import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";

import Button from "../Shared/button";
import { MAIN_TITLE } from "../../utils/appConstants";

const SsoForm = ({ sBrowser, handleSSOLogin }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    handleSSOLogin();
    setLoading(false);
  }, []);

  return (
    <>
      {/* <Button
        type="button"
        text="Login with SSO"
        handleClick={() => handleSSOLogin()}
      /> */}
      {sBrowser?.toLowerCase() === "apple safari" ?
        <div style={{ color: "#e36209", textAlign: "center" }}>Incompatible Browser Safari detected. {MAIN_TITLE} is built for Chrome, Mozilla or Edge devices</div>
        : null
      }
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FadeLoader
            width={5}
            radius={2}
            margin={2}
            height={15}
            color={"#4279f4"}
            loading={loading}
            // css={overrideContentLoading}
          />
        </div>
      ) : null}
    </>
  );
};

export default SsoForm;
