import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./login.css";
import SsoForm from "./ssoForm";
import {
  YEAR,
  VERSION,
  MAIN_TITLE,
  SSO,
  BASICAUTH,
  AUTH_MODE_ROOT,
} from "../../utils/appConstants";
import BasicAuthForm from "./basicAuthForm";
import { loginSSO, loginBasicAuth } from "../../api/api";
import AcnLogo from "../../assets/svgs/AcnBlackPurpleLogo.svg";
import AuthenticationService from "../../utils/authenticationService";

const LoginIndex = () => {
  const navigate = useNavigate();

  const [userCred, setUserCred] = useState({
    username: "",
    password: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  var sBrowser, sUsrAg = navigator.userAgent;
  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Edg") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }

  const handleChangeUserCred = (key, value) => {
    setUserCred({ ...userCred, [key]: value });
  };

  const handleSSOLogin = async () => {
    const resp = await loginSSO();
    if (resp) {
      window.location.href = resp?.redirect_url;
    }
  };

  const handleBasicAuthLogin = async () => {
    if (userCred?.username.trim() && userCred?.password.trim()) {
      setLoading(true);
      errorMsg !== "" && setErrorMsg("");
      loginBasicAuth(userCred)
        .then(async (res) => {
          if (res?.status === 200) {
            const { user_id, user_name, user_email } = res?.data;
            AuthenticationService.setLocalStorageUserAttributes(
              user_id,
              user_email,
              user_name
            );
            AuthenticationService.setupAxiosInterceptors();
            navigate("/chat");
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrorMsg(
            err?.response?.data?.detail || "Incorrect Username / Password"
          );
          setLoading(false);
        });
    } else if (!userCred?.username.trim() && userCred?.password.trim()) {
      setErrorMsg("Please enter your Username");
    } else if (userCred?.username.trim() && !userCred?.password.trim()) {
      setErrorMsg("Please enter your Password");
    } else {
      setErrorMsg("Please enter your Username & Password");
    }
  };

  const handleKeyDown = (event) => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Calls the Login API
      AUTH_MODE_ROOT.toLowerCase() === SSO.toLowerCase()
        ? handleSSOLogin()
        : handleBasicAuthLogin();
    }
  };

  return (
    <div className="login-root" onKeyDown={handleKeyDown}>
      <div className="login-header">
        <img src={AcnLogo} alt="Accenture Logo" width="50%" height="50%" />
      </div>
      <div className="login-content">
        <div className="login-content-inner">
          <div className="login-content-heading">Welcome to {MAIN_TITLE}!</div>
          {AUTH_MODE_ROOT.toLowerCase() === SSO.toLowerCase() ? (
            <SsoForm handleSSOLogin={handleSSOLogin} sBrowser={sBrowser} />
          ) : (
            <BasicAuthForm
              loading={loading}
              userCred={userCred}
              errorMsg={errorMsg}
              handleChangeUserCred={handleChangeUserCred}
              handleBasicAuthLogin={handleBasicAuthLogin}
            />
          )}
        </div>
      </div>
      <div className="login-footer">
        <div style={{ fontWeight: "bold" }}>Version {VERSION}</div>
        <div>Built for Edge, Chrome, Firefox.</div>
        <div>
          © {YEAR} {MAIN_TITLE} and its related entities. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default LoginIndex;
