import React from "react";

import LauncherIndex from "../components/Launcher";

const Launcher = () => {
  return (
    <>
      <LauncherIndex />
    </>
  );
};

export default Launcher;
