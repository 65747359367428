import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

import "./App.css";
import Login from "./pages/Login";
import Launcher from "./pages/Launcher";
import AuthRedirect from "./pages/AuthRedirect";
import { AuthenticatedRoute } from "./utils/authenticatedRoute";
// import AuthenticatedRoute from "./utils/authenticatedRoute"; react router dom v6 <

function App() {
  return (
    <div className="app-root">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/authredirect" element={<AuthRedirect />} />
          <Route
            exact
            path="/chat"
            element={
              <AuthenticatedRoute>
                <Launcher />
              </AuthenticatedRoute>
            }
          />
          {/* react router dom v6 <
            <AuthenticatedRoute
              exact
              path="/chat"
              element={<Launcher />}
            />
          */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
