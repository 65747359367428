export default {
  LOGIN_FAILURE: "Error occured during login, Please contact administrator.",

  REFRESH_TOKEN_FAILURE:
    "Error occured during refreshing tokens, Please contact administrator.",

  LOGOUT_SUCCESS: "Successfully logged out.",
  LOGOUT_FAILURE: "Error occured during logout, Please contact administrator.",

  AUTHCREDENTIAL_FAILURE:
    "Error occured during fetching Auth credential, Please contact administrator.",

  CHATHISTORY_FAILURE:
    "Error occured during fetching Chat history for the user, Please contact administrator.",

  FAQS_FAILURE:
    "Error occured during fetching Faq's for the user, Please contact administrator.",

  FEEDBACK_UPDATE_FAILURE:
    "Error occured during updating feedback for the response, Please contact administrator.",

  RESPONSE_REGENERATE_FAILURE:
    "Error occured during regenerating response for the question, Please contact administrator.",
};
