import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { Page, pdfjs, Document } from "react-pdf";
import FadeLoader from "react-spinners/FadeLoader";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import "./shared.css";
import MetricListPdf from "../../pdf/MetricList.pdf";
import QuestionListPdf from "../../pdf/QuestionList.pdf";

const pdfObj = {
  "Metric list": MetricListPdf,
  "Sample questions": QuestionListPdf,
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const styles = (theme) => ({
  root: {
    margin: 0,
    minWidth: "500px",
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
  closeButton: {
    color: "black",
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    minWidth: "500px",
    minHeight: "300px",
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PdfDialog = ({ type, openModal, handleClose }) => {

  const [loader, setLoader] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(pdfObj[`${type}`]);
  const [displayPageNumber, setDisplayPageNumber] = useState(1);

  const onDocumentLoadError = () => {
    setLoader(false);
  }

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
  }

  const onPageRenderSuccess =  async (page) => {
    await renderTextLayer(page);
    if (page._pageIndex === pageNumber - 1) {
      // setLoader(false);
    }
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
    setDisplayPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handlePageNumber = (event) => {
    // To show the typed value. React way of doing. Hence we have 2 variables
    // Displaynumber and pageNumber.
    setDisplayPageNumber(event.target.value);
  }

  const handlePageChange = (event) => {
    // PageChange happens even while typing. React only for enter key
    if (event.key === "Enter") {
      if (event.target.value <= numPages && event.target.value > 0) {
        let pageNumber = parseInt(event.target.value);
        setPageNumber(pageNumber);
        // this.props.updatePage(pageNumber);
      } else {
        setDisplayPageNumber(pageNumber);
      }
    }
  }

  const handleClosePdfDialog = () => {
    setDisplayPageNumber(1);
    setNumPages(null);
    setPageNumber(1);
    setPdfUrl("");
    handleClose();
  }

  const renderTextLayer = async (page) => {
    const textContent = await page.getTextContent();
    const viewport = page.getViewport({ scale: 1.0 });
  
    const textLayerDiv = document.createElement("div");
    textLayerDiv.className = "textLayer";
    textLayerDiv.style.width = `${viewport.width}px`;
    textLayerDiv.style.height = `${viewport.height}px`;
  
    pdfjs.renderTextLayer({
      textContent,
      container: textLayerDiv,
      viewport,
      textDivs: [],
      //enhanceTextSelection: true, // Optional: improves selection behavior
    });
  
    // Append text layer to the rendered page
    const canvasWrapper = document.querySelector(".react-pdf__Page");
    canvasWrapper.style.position = "relative"; // Ensure relative positioning
    canvasWrapper.appendChild(textLayerDiv);
  };
  
  

  return (
    <div>
      <Dialog
        maxWidth="md"
        // fullWidth={true}
        open={openModal}
        onClose={handleClosePdfDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          onClose={handleClosePdfDialog}
          className="modal-title"
          id="customized-dialog-title"
        >
          {type}
        </DialogTitle>
        <DialogContent dividers>
          {numPages ? (
            <>
              <div className="pdf-pagination">
                <div
                  className={
                    pageNumber <= 1 ? "pagination-arrow-disable" : "pagination-arrow"
                  }
                  onClick={pageNumber > 1 && previousPage}
                >
                  &lsaquo;
                </div>
                <div className="annotation-page-number">
                  <input
                    type="text"
                    name="name"
                    className="page-text-box"
                    value={displayPageNumber}
                    onChange={(e) => handlePageNumber(e)}
                    onKeyDown={(e) => handlePageChange(e)}
                  />
                  / {numPages}
                </div>
                <div
                  className={
                    pageNumber >= numPages ? "pagination-arrow-disable" : "pagination-arrow"
                  }
                  onClick={() => {pageNumber < numPages && nextPage()}}
                >
                  &rsaquo;
                </div>
              </div>
            </>
          ) : null}
          <Document
            file={pdfUrl}
            options={options}
            loading={
              <div className="pdfloader-root">
                <FadeLoader
                  width={5}
                  radius={2}
                  margin={2}
                  height={15}
                  color={"#4279f4"}
                />
                <div>Loading PDF...</div>
              </div>
            }
            onLoadError={() => onDocumentLoadError()}
            onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={true}
              renderAnnotationLayer={false}
              loading={
                <div className="pdfloader-root">
                  <FadeLoader
                    width={5}
                    radius={2}
                    margin={2}
                    height={15}
                    color={"#4279f4"}
                  />
                  <div>Loading page...</div>
                </div>
              }
              onRenderSuccess={(page) => onPageRenderSuccess(page)}
            />
            {/* {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))} */}
          </Document>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PdfDialog;
