export const staticSuggestions = [
  {
    "question": "Which clients showed an decline in chg over last qtr",
  },
  {
    "question": "Resources on exit path for project - Current count",
  }
];

export const staticFAQS = [
  {
    "type": "metric",
    "query": "Which databases/dashboard does i2B Assistant use to answer questions about KPIs and insights?",
    "answer": "i2B Assistant uses KPIs and metrics from Cross-Functional Analytics & Drill Down dashboard of i2B. For current release i2B Assistant is trained only on the data from Cross Functional Analytics.",
    "link": [{"text": "Please click here.", "type": "metric"}]
  },
  {
    "type": "question",
    "query": "Are there any examples of questions i2B Assistant can answer?",
    "answer": "Here is a list of questions it can address. Use these as a guide, template or reference.",
    "link": [{"text": "Please click here.", "type": "question"}]
  },
  {
    "type": "normal",
    "query": "Are there any best practices that a user should abide by when interacting with the i2B Assistant?",
    "answer": "1. When asking for key performance indicators (KPIs) of an entity, specify the name of the Market, Market Unit, Service Group or Client Group, Client or DU in your question.\n\n2. Indicate MTD, QTD, YTD in your query. Otherwise i2B Assistant may provide YTD or random figures.\n\n3. Include the names of KPIs in conversations with i2B Assistant, e.g., LCR, Chargeability (chg) or Headcount (HC).\n\n4. Avoid project specific nomenclature, abbreviations or jargons and use the Accenture standard KPI names for better response.",
    "link": null
  },
  {
    "type": "normal",
    "query": "What dimensions of metrics can i2B Assistant process?",
    "answer": "The tool can help with metrics for various portfolios across ATCI- all Markets, Market Units (MU), Client Groups (CG), Clients (Accounts), Projects.\n\nFor Headcount, Productivity and LCR related metrics, i2B Assistant uses Governance Rules to determine hierarchy. Please reach out to your Market BizOps or the Central BizOps team to understand more about Governance Rules.\n\nFor other metrics, the hierarchy used is as structured in Abacus systems.",
    "link": null
  },
  {
    "type": "normal",
    "query": "Tell me about the dashboard data sources?",
    "answer": "Sources of data for the tool remains the same as the source of data on i2B, which is primarily Abacus systems.\n\nHeadcount: Abacus with governance rules applied.\n\nChargeability, Utilization and Other Productivity parameters: Abacus with governance rules applied.\n\nLCR: Abacus with governance rules applied - Employees on GCP are excluded.\n\nDemand, Overdue Demand, FHL, Fulfilled Demands: Abacus.\n\nQtr. Fulfillment Commitment: Manual input from TFS team.\n\nExit in Pipeline: Service Now.\n\nLanding Projection: Computed field.",
    "link": null
  },
];

