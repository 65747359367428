import React from "react";

import "./fullscreenmode.css";
import FSMHeaderIndex from "./Header";
import FSMSidebarIndex from "./Sidebar";
import FSMChatWindowIndex from "./ChatWindow";

const FullScreenModeIndex = () => {
  return (
    <>
        <div className="fsm-root">
        {/* <div className="fsm-content"> */}
        <FSMSidebarIndex/>
        <div className="fsm-content-chatwindow">
          <FSMChatWindowIndex />
        </div>
        {/* <div className="fsm-content-sidebar">
          <FSMSidebarIndex />
        </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default FullScreenModeIndex;
