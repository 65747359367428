import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import "./shared.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: "black",
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* <IconButton
        aria-label="close"
        onClick={onClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton> */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SharedDialog = ({ openModal, handleClose }) => {
  return (
    <div>
      <Dialog
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          onClose={handleClose}
          className="modal-title"
          id="customized-dialog-title"
        >
          Notice
        </DialogTitle>
        <DialogContent dividers>
          <div className="modal-content">
            You have already logged in another session or the previous session
            was abruptly closed. Terminating the older session !!!
          </div>
        </DialogContent>
        <DialogActions>
          <div
            autoFocus
            onClick={() => {
              handleClose();
            }}
            className="modal-button"
          >
            Agree
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SharedDialog;
