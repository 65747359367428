import axios from "axios";
import * as SecureLS from "secure-ls";

import {
  API_ROOT,
  LS_USER_ID,
  LS_USER_NAME,
  LS_USER_EMAIL,
  LS_CHAT_MESSAGE,
  INVALID_TOKEN,
  EXPIRED_TOKEN,
  INVALID_SESSION,
  NOT_AUTHENTICATED,
  INVALID_REFRESH_TOKEN,
  LS_ENCRYPT_SECRET_KEY,
} from "./appConstants";

class AuthenticationService {
  constructor() {
    this.ls = new SecureLS({
      encodingType: "aes",
      encryptionSecret: LS_ENCRYPT_SECRET_KEY,
    });
  }
  
  setLocalStorageUserAttributes(id, email, username) {
    this.ls.set(LS_USER_ID, id);
    this.ls.set(LS_USER_EMAIL, email);
    this.ls.set(LS_USER_NAME, username);
  }

  clearLocalStorageAttributes() {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_USER_NAME);
    localStorage.removeItem(LS_USER_EMAIL);
    localStorage.removeItem(LS_CHAT_MESSAGE);
  }

  getLocalStorageUserId() {
    let userId =
      typeof window !== "undefined" ? this.ls.get(LS_USER_ID) : null;
    return userId;
  }

  getLocalStorageUserName() {
    let userName =
      typeof window !== "undefined" ? this.ls.get(LS_USER_NAME) : null;
    return userName;
  }

  getLocalStorageUserEmail() {
    let userEmail =
      typeof window !== "undefined" ? this.ls.get(LS_USER_EMAIL) : null;
    return userEmail;
  }

  getLocalStorageChatMessage() {
    let chatMesg =
      typeof window !== "undefined" ? localStorage.getItem(LS_CHAT_MESSAGE) : null;
    return chatMesg;
  }

  isUserLoggedIn() {
    let email =
      typeof window !== "undefined"
        ? this.ls.get(LS_USER_EMAIL)
        : null;
    if (email) return true;
    return false;
  }

  // this method is needed to check token existence and register it even during screen refresh
  isUserLoggedInAndTokenRegistered() {
    if (!this.isUserLoggedIn()) {
      return false;
    }
    this.setupAxiosInterceptors();
    return true;
  }

  setupAxiosInterceptors() {
    // Request interceptor
    axios.interceptors.request.use(
      (config) => {
        if (this.isUserLoggedIn()) {
          axios.defaults.withCredentials = true;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // Response interceptor
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        if (
          error?.response?.status === 401 &&
          (error?.response?.data?.detail.toLowerCase() === INVALID_TOKEN ||
            error?.response?.data?.detail.toLowerCase() === INVALID_SESSION ||
            error?.response?.data?.detail.toLowerCase() === NOT_AUTHENTICATED)
        ) {
          this.clearLocalStorageAttributes();
          window.location.replace("/");
          return Promise.reject(error);
        }
        // if refresh token API fails redirect to login
        if (
          error?.response?.status === 498 &&
          error?.response?.data?.detail.toLowerCase() ===
            INVALID_REFRESH_TOKEN &&
          originalRequest.url === API_ROOT + "refresh-token"
        ) {
          this.clearLocalStorageAttributes();
          window.location.replace("/");
          return Promise.reject(error);
        }
        // call refresh token API if you get 419
        if (
          error?.response?.status === 419 &&
          error?.response?.data?.detail.toLowerCase() === EXPIRED_TOKEN &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          return axios.post(API_ROOT + "refresh-token").then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              return axios(originalRequest);
            }
          });
        }
        return Promise.reject(error);
      }
    );
  }
}

export default new AuthenticationService();
