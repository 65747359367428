import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FadeLoader from "react-spinners/FadeLoader";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./shared.css";
import { updateFeedback } from "../../api/api";
import ThumbsUp from "../../assets/svgs/Thumb_up_modal.svg";
import ThumbsDown from "../../assets/svgs/Thumb_down_modal.svg";

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    color: "black",
    position: "absolute",
    right: 0,
    top: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { type, loading, children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div>{children}</div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => {
          !loading && onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    boxSizing: "border-box",
    padding: theme.spacing(2),
    backgroundColor: "#F2F2F2",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    boxSizing: "border-box",
    padding: theme.spacing(2),
    backgroundColor: "#F2F2F2",
  },
}))(MuiDialogActions);

const PositiveText = "What do you like about the response? (optional)";
const NegativeText =
  "Please help me understand the issue with the response, and how could it be improved? (Optional)";
const PositiveOptions = {
  Correct: false,
  "Easy to understand": false,
  "Concise and complete": false,
};
const NegativeOptions = {
  "Inaccurate Data": false,
  "Incomplete Information": false,
  "Irrelevant Information": false,
  "Share your ideas for improvement": false,
};

const FeedbackDialog = ({
  data,
  messageList,
  setMessageList,
  regenerateNum,
  openModal,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    text: "",
    optionsSelected: [],
    options: data?.type === 1 ? PositiveOptions : NegativeOptions,
  });

  const handleChange = (key, eve) => {
    if (key === "text") {
      setFeedbackData({ ...feedbackData, [key]: eve.target.value });
    } else {
      let tempOptionsSelected = [...feedbackData.optionsSelected];
      const alreadyIndex = tempOptionsSelected.findIndex((opt) => {
        return opt === eve.target.name;
      });
      if (alreadyIndex < 0) {
        tempOptionsSelected.push(eve.target.name);
      } else {
        tempOptionsSelected.splice(alreadyIndex, 1);
      }
      setFeedbackData({
        ...feedbackData,
        optionsSelected: tempOptionsSelected,
        options: {
          ...feedbackData.options,
          [eve.target.name]: eve.target.checked,
        },
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let tempReqObj = {
      app_id: 1,
      value: data?.type, 
      msg_id: data?.msg_id,
      version: data?.data?.version,
      fdk_json: {
        text: feedbackData?.text,
        options: feedbackData?.optionsSelected,
      },
    };
    const resp = await updateFeedback(tempReqObj);
    if (resp) {
      let tempMsgList = structuredClone(messageList);
      messageList?.data &&
        Object.keys(messageList.data).length > 0 &&
        Object.keys(messageList.data).forEach((date) => {
          let tempDateMsgList = [...tempMsgList.data[`${date}`]];
          let msgIndex =
            tempDateMsgList?.length > 0 &&
            tempDateMsgList.findIndex((d) => d?.msg_id === data?.msg_id);
          if (msgIndex > -1) {
            tempDateMsgList[msgIndex]["data"][regenerateNum]["feedback"] =
              data?.type;
            tempMsgList.data[`${date}`] = tempDateMsgList;
            return;
          }
        });
      setMessageList(tempMsgList);
    }
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="feedback-dialog"
      >
        <DialogTitle
          type={data?.type}
          loading={loading}
          onClose={handleClose}
          id="feedback-dialog-title"
          className="modal-title-feedback"
        >
          <div className="modal-title-feedback-club">
            {data?.type && (
              <img
                alt="Feedback"
                className="modal-title-feedback-image"
                src={data?.type === 1 ? ThumbsUp : ThumbsDown}
              />
            )}
            Provide additional feedback.
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="modal-content-feedback">
            <textarea
              rows={5}
              cols={75}
              disabled={loading}
              name="feedbackText"
              value={feedbackData?.text}
              className="modal-content-textarea"
              onChange={(e) => handleChange("text", e)}
              placeholder={data?.type === 1 ? PositiveText : NegativeText}
            />
            {data?.type === -1 ? (
              <>
                <FormGroup>
                  {Object.keys(feedbackData?.options)?.length > 0 &&
                    Object.keys(feedbackData?.options).map((opt) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={opt}
                              size="small"
                              disableRipple
                              checked={feedbackData?.options[opt]}
                              onChange={(e) => handleChange("options", e)}
                            />
                          }
                          label={
                            <div className="modal-content-label">{opt}</div>
                          }
                        />
                      );
                    })}
                </FormGroup>
              </>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <div className="modal-foooter-loader">
              <FadeLoader
                width={5}
                radius={2}
                margin={2}
                height={15}
                color={"#4279f4"}
                loading={loading}
              />
            </div>
          ) : (
            <div
              autoFocus
              onClick={() => handleSubmit()}
              disabled={
                data?.type === -1 && feedbackData?.optionsSelected?.length <= 0
              }
              className={
                data?.type === -1 && feedbackData?.optionsSelected?.length <= 0
                  ? "modal-button-feedback-disabled"
                  : "modal-button-feedback"
              }
            >
              Submit feedback
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackDialog;
