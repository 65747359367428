import React from "react";
import { Navigate, useLocation } from "react-router-dom";
// import { Route, Redirect } from "react-router-dom"; react router dom v6 <

import AuthenticationService from "./authenticationService";

export const AuthenticatedRoute = ({ children }) => {
  const location = useLocation();

  if (!AuthenticationService.isUserLoggedInAndTokenRegistered()) {
    // user is not authenticated
    return (
      <Navigate
        to="/"
        replace
        state={{ sharedRoute: location.pathname + location.search }}
      />
    );
  }
  return children;
};

// react router dom v6 <
// const AuthenticatedRoute = (props) => {
//   let sharedRoute = props.location.pathname + props.location.search;
//   if (AuthenticationService.isUserLoggedInAndTokenRegistered()) {
//     return <Route {...props} />;
//   } else {
//     return (
//       <Redirect to={{ pathname: "/", state: { sharedRoute: sharedRoute } }} />
//     );
//   }
// }

// export default AuthenticatedRoute;
