import {
  Popover,
  Divider,
  MenuItem,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { logout } from "../../../../api/api";
import AuthenticationService from "../../../../utils/authenticationService";

const useStyles = makeStyles((theme) => ({
  iconButtons: {
    padding: 0,
    marginLeft: "1%",
  },
}));

const HeaderProfile = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorElProfileMenu, setAnchorElProfileMenu] = useState(null);

  const handleProfileMenuClick = (event) => {
    setAnchorElProfileMenu(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorElProfileMenu(null);
  };

  const openPM = Boolean(anchorElProfileMenu);
  const idPM = openPM ? "header-profile-popover" : undefined;

  const handleLogout = async () => {
    const resp = await logout();
    if (resp) {
      navigate("/");
      AuthenticationService.clearLocalStorageAttributes();
    }
  };

  return (
    <>
      <IconButton
        className={classes.iconButtons}
        onClick={(e) => handleProfileMenuClick(e)}
      >
        <AccountCircleIcon
          fontSize="large"
          style={{ fill: "#ffffff", cursor: "pointer" }}
        />
      </IconButton>
      <Popover
        id={idPM}
        open={openPM}
        anchorEl={anchorElProfileMenu}
        onClose={handleProfileMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem style={{ fontWeight: "bold" }}>
          {AuthenticationService.getLocalStorageUserEmail()}
        </MenuItem>
        <MenuItem>{AuthenticationService.getLocalStorageUserName()}</MenuItem>
        <Divider />
        <MenuItem className="menuItemText" onClick={() => handleLogout()}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};

export default HeaderProfile;
