import React from "react";
import { RecoilRoot } from "recoil";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <RecoilRoot>
        <App />
      </RecoilRoot>
      <ToastContainer
        rtl={false}
        autoClose={5000}
        pauseOnFocusLoss
        draggable={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnHover={false}
        position="bottom-left"
        hideProgressBar={false}
      />
    </>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
