import React from "react";

import MessageIndex from "../Messages";

const FSMChatMessage = ({ message }) => {
  return (
    <div id={message?.msg_id}>
      <MessageIndex message={message} />
    </div>
  );
};

export default FSMChatMessage;
