// Generate UUID

// Method 1:- uuidv4M1: 812ms
export const uuidv4M1 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
// Method 2:- uuidv4M2: 1237ms
export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (crypto.getRandomValues(new Uint32Array(1))[0]/2**32) * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};
// Method 2:- uuidv4M2: 263ms
function s4() {
  return Math.floor((1 + (crypto.getRandomValues(new Uint32Array(1))[0]/2**32)) * 0x10000)
    .toString(16)
    .substring(1);
}
export const uuidv4M3 = () => {
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};
// Method 4:- uuidv4M4: -ms(Defaultly available in js cryptographically secure random number generator.)
export const uuidv4M4 = () => {
  return crypto.randomUUID();
};

// Currency Formatter
export const moneyPrettier = (num, precision = 2) => {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = "$" + (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return "$" + num;
};