import React from "react";
import AuthenticationService from "../../../../utils/authenticationService";

const HtmlMessage = (props) => {
  return (
    <>
      <div
        style={{ marginBottom: "1vh" }}
        dangerouslySetInnerHTML={{__html: props?.msg_id === -11 ?
          props?.text.replace("@@USER_FNAME@@", AuthenticationService.getLocalStorageUserName())
          : props?.text}}
      />
    </>
  );
};

export default HtmlMessage;
