import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import FadeLoader from "react-spinners/FadeLoader";

import Button from "../Shared/button";
import CommonInput from "../Shared/input";

const BasicAuthForm = (props) => {
  const {
    loading,
    userCred,
    errorMsg,
    handleChangeUserCred,
    handleBasicAuthLogin,
  } = props;

  return (
    <>
      <CommonInput
        id="username"
        placeholder="Username"
        type="text"
        autofocus={true}
        label="Username*"
        value={userCred.username}
        handleChange={handleChangeUserCred}
        handleKeyDown={() => {}}
      />
      <CommonInput
        id="password"
        placeholder="Password"
        type="password"
        autofocus={false}
        label="Password*"
        value={userCred.password}
        handleChange={handleChangeUserCred}
        handleKeyDown={() => {}}
      />
      {errorMsg ? (
        <div className="login-content-error">
          <div>
            <InfoIcon style={{ fill: "#ed6c02" }} />
          </div>
          <div>{errorMsg}</div>
        </div>
      ) : null}
      <div className="login-content-button">
        {loading ? (
          <div style={{ margin: "0 auto" }}>
            <FadeLoader
              width={5}
              radius={2}
              margin={2}
              height={15}
              color={"#4279f4"}
              loading={loading}
              // css={overrideContentLoading}
            />
          </div>
        ) : (
          <Button
            type="submit"
            text="Login"
            handleClick={() => handleBasicAuthLogin()}
          />
        )}
      </div>
    </>
  );
};

export default BasicAuthForm;
