import React from "react";

import "./shared.css";

const Button = (props) => {
  const { type, text, disabled, handleClick, textColor, backgroundColor } = props;

  const styleList = {
    color: textColor ? textColor : "",
    cursor: disabled ? "not-allowed" : "",
    backgroundColor: backgroundColor ? backgroundColor : "",
  };

  return (
    <button
      style={styleList}
      className="my-button"
      type={type || "button"}
      disabled={disabled || false}
      onClick={() => handleClick()}
    >
      {text}
    </button>
  );
};

export default Button;
