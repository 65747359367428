import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";

import "./fsmsidebar.css";
import FaqAccordian from "./faqAccordian";
import { getFaqs } from "../../../../api/api";
import ChatSvg from "../../../../assets/svgs/Chat.svg";
import { staticFAQS } from "../../../../utils/mockData";
import { MAIN_TITLE } from "../../../../utils/appConstants";
import AuthenticationService from "../../../../utils/authenticationService";

const FSMSidebarIndex = () => {
  const [faqInfo, setFaqInfo] = useState({
    list: null,
    loading: false,
    dataRefreshData: null,
  });

  useEffect(() => {
    async function fetchFaqList() {
      const resp = await getFaqs();
      if (resp?.data) {
        setFaqInfo({
          ...faqInfo,
          loading: false,
          list: staticFAQS,
          dataRefreshData: resp?.data?.datarefresh_date,
        });
      } else {
        setFaqInfo({ ...faqInfo, loading: false });
      }
    }
    setFaqInfo({ ...faqInfo, loading: true });
    fetchFaqList();
  }, []);

  const dateFormatting = (timeStamp) => {
    if (!timeStamp) {
      return "-";
    }
    let date = new Date(timeStamp);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + " " + month + " " + year;
  };

  return (
    <>
      <div className="fsm-sidebar-root">
        <div className="fsm-sidebar-header">
          <img src={ChatSvg} alt="ChatIcon" className="fsm-sidebar-chat-icon" />
          {MAIN_TITLE} <span className="fsm-sidebar-headerdata"> - powered by GenAI</span>
        </div>
        {/* <div className="fsm-sidebar-content">
          <div className="fsm-sidebar-content-heading">About {MAIN_TITLE}</div>
          <div className="fsm-sidebar-content-faqs">
            {faqInfo?.loading ? (
              <div className="fsm-sidebar-faq-loader">
                <FadeLoader
                  width={5}
                  radius={2}
                  margin={2}
                  height={15}
                  color={"#4279f4"}
                  loading={faqInfo?.loading}
                />
              </div>
            ) : faqInfo?.list?.length === 0 ? (
              <>
                <div
                  className="fsm-sidebar-content-faq-ques"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  No Faq's found
                </div>
              </>
            ) : (
              <>
                {faqInfo?.list?.length > 0 &&
                  faqInfo.list.map((faq, ind) => {
                    return <FaqAccordian key={ind} faq={faq} />;
                  })}
              </>
            )}
          </div>
        </div> */}
        <div className="fsm-sidebar-footer">
          <div>Data source:</div>
          <div className="fsm-sidebar-datasource-list">- Cross-Functional Analytics as of {dateFormatting(faqInfo?.dataRefreshData?.e2e_updated_date)}</div>
          {/*<div>{MAIN_TITLE} is powered by Converser</div> */}
        </div>
      </div>
    </>
  );
};

export default FSMSidebarIndex;
