import React from "react";

import AuthenticationService from "../../../../utils/authenticationService";

const TextMessage = (props) => {
  return (
    <>
      <div style={{ marginBottom: "1vh" }}>
        {
          props?.msg_id === -1 ?
            props?.text.replace("@@USER_FNAME@@", AuthenticationService.getLocalStorageUserName())
            : props?.text
        }
        {/* {props?.text} */}
      </div>
    </>
  );
};

export default TextMessage;
