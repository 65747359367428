import React from "react";
import { useRecoilState } from "recoil";

import {
  MSG_OBJ,
  TOLOCALE_LOCALES,
  TOLOCALE_OPTIONS,
} from "../../../../utils/appConstants";
import { uuidv4M3 } from "../../../../utils/appFunction";
import { chatMesgState } from "../../../../recoil/atoms";
import { staticSuggestions } from "../../../../utils/mockData";
import AuthenticationService from "../../../../utils/authenticationService";

const ChatSuggestion = ({ showSuggestion }) => {
  const [messageList, setMessageList] = useRecoilState(chatMesgState);

  const handleSendMessage = (suggestion) => {
    if (suggestion !== "") {
      let tempMsgObj = structuredClone(MSG_OBJ);
      tempMsgObj["id"] = uuidv4M3();
      tempMsgObj["created_date"] = new Date().toISOString();
      const tempDataObj = { response: [{ type: "text", text: suggestion }] };
      tempMsgObj["data"].push(tempDataObj);
      tempMsgObj["author"] = parseInt(
        AuthenticationService.getLocalStorageUserId()
      );
      let tempMsgList = structuredClone(messageList);
      let currentDate = new Date().toLocaleDateString(
        TOLOCALE_LOCALES,
        TOLOCALE_OPTIONS
      );
      // messageList.hasOwnProperty('currentDate') or currentDate in Object.keys(messageList)
      if (messageList?.data.hasOwnProperty(currentDate)) {
        // today date is in Object.keys and push message in the array
        let tempCurrentDateMsgList = [
          ...tempMsgList.data[`${currentDate}`],
          tempMsgObj,
        ];
        tempMsgList.data[`${currentDate}`] = tempCurrentDateMsgList;
      } else {
        // add today date in Object.keys and push message in the array
        tempMsgList.data[`${currentDate}`] = [tempMsgObj];
      }
      tempMsgList.wsSendMsg = tempMsgObj;
      setMessageList(tempMsgList);
    }
  };

  return (
    <React.Fragment>
      {showSuggestion ? (
        <div className="suggestion-root">
          <div className="suggestion-inner">
            {staticSuggestions &&
              staticSuggestions.map((sug, ind) => {
                return (
                  <div
                    key={ind}
                    className="suggestion-text-root"
                    onClick={() => handleSendMessage(sug?.question)}
                  >
                    <span className="suggestion-text">{sug?.question}</span>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ChatSuggestion;
