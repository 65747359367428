import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  MSG_OBJ,
  TOLOCALE_LOCALES,
  TOLOCALE_OPTIONS,
} from "../../../../utils/appConstants";
import { uuidv4M3 } from "../../../../utils/appFunction";
import SendIcon from "../../../../assets/svgs/Send_button.svg";
import { lastBotResp, chatMesgState } from "../../../../recoil/atoms";
import AuthenticationService from "../../../../utils/authenticationService";

const textMessage = {
  "0": "Similar questions:",
  "1": "Did you mean:",
  "2": "Did you mean:",
  "3": ""
};

const ButtonMessage = (props) => {
  const lastBotMsg = useRecoilValue(lastBotResp);
  const [isClicked, setIsClicked] = useState(false);
  const [messageList, setMessageList] = useRecoilState(chatMesgState);

  const handleFeedbackClick = (question) => {
    if (question !== "") {
      let tempMsgObj = structuredClone(MSG_OBJ);
      tempMsgObj["id"] = uuidv4M3();
      tempMsgObj["created_date"] = new Date().toISOString();
      const tempDataObj = { response: [{ type: "text", text: question }] };
      tempMsgObj["data"].push(tempDataObj);
      tempMsgObj["author"] = parseInt(
        AuthenticationService.getLocalStorageUserId()
      );
      let tempMsgList = structuredClone(messageList);
      let currentDate = new Date().toLocaleDateString(
        TOLOCALE_LOCALES,
        TOLOCALE_OPTIONS
      );
      // messageList.hasOwnProperty('currentDate') or currentDate in Object.keys(messageList)
      if (messageList?.data.hasOwnProperty(currentDate)) {
        // today date is in Object.keys and push message in the array
        let tempCurrentDateMsgList = [
          ...tempMsgList.data[`${currentDate}`],
          tempMsgObj,
        ];
        tempMsgList.data[`${currentDate}`] = tempCurrentDateMsgList;
      } else {
        // add today date in Object.keys and push message in the array
        tempMsgList.data[`${currentDate}`] = [tempMsgObj];
      }
      tempMsgList.wsSendMsg = tempMsgObj;
      setMessageList(tempMsgList);
      setIsClicked(true);
    }
  };

  return (
    <>
      {!isClicked && lastBotMsg?.msg_id === props?.msg_id ? (
        <div className="fsm-message--button-container">
          <div className="fsm-message--button-didyoumean">{textMessage[props?.res_code]}</div>
          {props?.button_value?.length > 0 &&
            props.button_value.map((b, i) => {
              return (
                <div
                  key={i}
                  className="fsm-message--button"
                  onClick={() => handleFeedbackClick(b)}
                >
                  <img
                    alt="Send"
                    src={SendIcon}
                    className="fsm-message--button-image"
                  />
                  {b}
                </div>
              );
            })}
        </div>
      ) : null}
    </>
  );
};

export default ButtonMessage;
