import React from "react";

import "./shared.css";

const Input = (props) => {
  const {
    id,
    idx,
    type,
    label,
    value,
    minVal,
    disabled,
    autofocus,
    placeholder,
    handleChange,
    handleKeyDown,
    noMarginBottom,
    noBorder,
  } = props;
  return (
    <div
      style={{ width: "100%" }}
      className={noMarginBottom ? "" : "form-group"}
    >
      {label !== "nolabel" && <div className="form-label">{label}</div>}
      <input
        value={value}
        min={minVal || 0}
        type={type || "text"}
        className="form-input"
        disabled={disabled || false}
        autoFocus={autofocus || false}
        placeholder={placeholder || ""}
        onKeyDown={(e) => handleKeyDown(e)}
        style={{ border: noBorder ? "none" : "" }}
        onChange={(e) => handleChange(id, e.target.value, idx)}
      />
    </div>
  );
};

export default Input;
