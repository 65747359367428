import React from "react";

import LoginIndex from "../components/Login";

const Login = () => {
  return (
    <>
      <LoginIndex />
    </>
  );
};

export default Login;
