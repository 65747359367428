import React from "react";

import "./launcher.css";
import BotModeIndex from "./BotMode";
import FullScreenModeIndex from "./FullScreenMode";
import { BOT, VIEW_MODE_ROOT } from "../../utils/appConstants";

const LauncherIndex = () => {
  return (
    <>
      {VIEW_MODE_ROOT === BOT ? (
        <BotModeIndex launcherPosition={false} />
      ) : (
        <FullScreenModeIndex />
      )}
    </>
  );
};

export default LauncherIndex;
