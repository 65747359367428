import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  makeStyles,
  TableContainer,
  TableSortLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";

import {
  acnCurrencyCol,
  acnReferenceMonthNames,
} from "../../../../utils/appConstants";
import { moneyPrettier } from "../../../../utils/appFunction";

const useStyles = makeStyles({
  tableContainer: {
    // maxHeight: "100%",
    marginBottom: "1vh",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  tableHeadCell: {
    top: 0,
    color: "black",
    padding: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "hidden",
    // position: "sticky",
    wordWrap: "break-word",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    backgroundColor: "#CCCbce",
    textTransform: "capitalize",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  },
  tableBodyCell: {
    color: "black",
    padding: "10px",
    fontSize: "14px",
    overflow: "hidden",
    wordWrap: "break-word",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  },
  tableBodyCellBold: {
    color: "black",
    padding: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

const TableMessage = (props) => {
  const classes = useStyles();

  const [tHead, setTHead] = useState([]);
  const [tBody, setTBody] = useState([]); // array of objects
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [sortedTableBody, setSortedTableBody] = useState(
    props?.table_body || []
  ); // array of arrays

  useEffect(() => {
    if (props?.table_header && props?.table_body) {
      // table header
      let tempTHead = [];
      props?.table_header &&
        props.table_header.forEach((col, ind) => {
          const tHeadObj = {
            name: col,
            align: "left",
            width: `${100 / props?.table_header.length}%`,
          };
          tempTHead.push(tHeadObj);
        });
      setTHead(tempTHead);
      // table content
      let tempTBody = [];
      props?.table_body &&
        props.table_body.forEach((row, indr) => {
          const tBodyRowObj = {};
          props?.table_header &&
            props.table_header.forEach((col, indc) => {
              tBodyRowObj[col] = row[indc];
            });
          tempTBody.push(tBodyRowObj);
        });
      setTBody(tempTBody);
    }
  }, []);

  const transformTableBody = (data) => {
    let tempBody = [];
    data &&
      data.forEach((row) => {
        let tempBodyArr = [];
        props?.table_header &&
          props.table_header.forEach((col) => {
            tempBodyArr.push(row[col]);
          });
        tempBody.push(tempBodyArr);
      });
    return tempBody;
  };

  const sortFunction = (sortField, sortOrder) => {
    if (sortField) {
      if (sortField?.toLowerCase() === "month") {
        const sorted = [...tBody].sort((a, b) => {
          if (a[sortField] === null) return 1;
          if (b[sortField] === null) return -1;
          if (a[sortField] === null && b[sortField] === null) return 0;
          const comparableA = a[sortField]?.toLowerCase().substring(0, 3);
          const comparableB = b[sortField]?.toLowerCase().substring(0, 3);
          return (
            (acnReferenceMonthNames.indexOf(comparableA) -
              acnReferenceMonthNames.indexOf(comparableB)) *
            (sortOrder === "asc" ? 1 : -1)
          );
        });
        setTBody(sorted);
        setSortedTableBody(transformTableBody(sorted));
      } else {
        const sorted = [...tBody].sort((a, b) => {
          if (a[sortField] === null) return 1;
          if (b[sortField] === null) return -1;
          if (a[sortField] === null && b[sortField] === null) return 0;
          const regex = /[+-]?([0-9]*[.])?[0-9]+/g;
          const containsNumericA = /[+-]?([0-9]*[.])?[0-9]+/.test(a[sortField]);
          const containsNumericB = /[+-]?([0-9]*[.])?[0-9]+/.test(b[sortField]);
          if (containsNumericA && containsNumericB) {
            let tempA = Array.from(
              a[sortField].toString().matchAll(regex),
              (m) => m[0]
            );
            let tempB = Array.from(
              b[sortField].toString().matchAll(regex),
              (m) => m[0]
            );
            return (tempA - tempB) * (sortOrder === "asc" ? 1 : -1);
          }
          return (
            a[sortField]
              .toString()
              .localeCompare(b[sortField].toString(), "en", {
                numeric: true,
              }) * (sortOrder === "asc" ? 1 : -1)
          );
        });
        setTBody(sorted);
        setSortedTableBody(transformTableBody(sorted));
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    sortFunction(property, isAsc ? "desc" : "asc");
  };

  const transformNegativeValues = (val) => {
    if (val?.toString().startsWith("$-")) {
      return "($" + val.slice(2) + ")";
    } else {
      return val;
    }
  };

  const transformValues = (key, val) => {
    if (acnCurrencyCol.includes(key.toLowerCase())) {
      const tempVal = moneyPrettier(val);
      return transformNegativeValues(tempVal);
    } else {
      return transformNegativeValues(val);
    }
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table
          // stickyHeader
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              {tHead &&
                tHead.map((thc, ind) => {
                  return (
                    <TableCell
                      key={ind}
                      width={thc?.width}
                      align={thc?.align || "left"}
                      className={classes.tableHeadCell}
                      style={{
                        borderRight:
                          ind === tHead?.length - 1
                            ? "none"
                            : "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === thc?.name}
                        direction={orderBy === thc?.name ? order : "asc"}
                        onClick={() => handleRequestSort(thc?.name)}
                      >
                        {thc?.name}
                        {orderBy === thc?.name ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.table_body && props?.table_body.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={12}
                  style={{ textAlign: "center" }}
                  className={classes.tableBodyCellBold}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
            {tBody &&
              tBody.length > 0 &&
              tBody.map((tbr, ind) => {
                return (
                  <TableRow key={ind}>
                    {tHead?.length > 0 &&
                      tHead.map((cell, indj) => (
                        <TableCell
                          key={indj}
                          scope="row"
                          align="left"
                          component="th"
                          style={{
                            borderRight:
                              indj === tbr?.length - 1
                                ? "none"
                                : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          className={classes.tableBodyCell}
                          width={`${100 / props?.table_header.length}%`}
                          title={transformValues(cell?.name, tbr[cell?.name])}
                        >
                          {transformValues(cell?.name, tbr[cell?.name])}
                        </TableCell>
                      ))}
                  </TableRow>
                );
              })}
            {/* {sortedTableBody &&
              sortedTableBody.length > 0 &&
              sortedTableBody.map((tbr, ind) => {
                return (
                  <TableRow key={ind}>
                    {tbr?.length > 0 &&
                      tbr.map((cell, indj) => (
                        <TableCell
                          key={indj}
                          scope="row"
                          title={cell}
                          align="left"
                          component="th"
                          style={{
                            borderRight:
                              indj === tbr?.length - 1
                                ? "none"
                                : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          className={classes.tableBodyCell}
                          width={`${100 / props?.table_header.length}%`}
                        >
                          {transformNegativeValues(cell)}
                        </TableCell>
                      ))}
                  </TableRow>
                );
              })} */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableMessage;
