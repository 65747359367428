// APP VERSION
export const VERSION = `2.0.0`;
export const YEAR = `2023`;

// APP AUTH MODE
export const BASICAUTH = `basicauth`;
export const SSO = `sso`;

// APP VIEW MODE
export const FULLSCREEN = `fullscreen`;
export const BOT = `bot`;

// APP BASEURL
export const LS_ENCRYPT_SECRET_KEY = `${process.env.REACT_APP_LOCALSTORAGE_SECRET_KEY}`;
export const WEBSOCKET_ROOT = `${process.env.REACT_APP_WEBSOCKET_URL}`;
export const VIEW_MODE_ROOT = `${process.env.REACT_APP_VIEW_MODE}`;
export const AUTH_MODE_ROOT = `${process.env.REACT_APP_AUTH_MODE}`;
export const API_ROOT = `${process.env.REACT_APP_API_URL}`;

// APP TITLE
export const MAIN_TITLE = `i2B Assistant`;
export const SUB_TITLE = `Chatbot`;

// LOCAL STORAGE VARIABLE
export const LS_CHAT_MESSAGE = "converser_chat_message";
export const LS_USER_NAME = "converser_username";
export const LS_USER_EMAIL = "converser_email";
export const LS_USER_ID = "converser_userid";

// LOGIN REGEX PATTERN
export const REGEX_USERNAME =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;

// AUTHENTICATION SERVICE MESSAGES
export const INVALID_REFRESH_TOKEN = "invalid refresh token";
export const NOT_AUTHENTICATED = "not authenticated";
export const INVALID_SESSION = "invalid session";
export const INVALID_TOKEN = "invalid access token";
export const EXPIRED_TOKEN = "expired token";

// toLocaleDateString PARAMS
export const TOLOCALE_LOCALES = "en-US";
export const TOLOCALE_OPTIONS = {
  hour12: true,
  day: "2-digit",
  month: "short",
  year: "numeric",
  timeZone: "Asia/Kolkata",
};

// MESSAGE FORMAT
export const MSG_OBJ = {
  id: -1,
  data: [],
  author: "me",
  created_date: "",
};

export const RETENTION_MSG = `${MAIN_TITLE} only stores up to 3 days of conversation history per our retention policy.`;

export const INTRO_MSG = {
  msg_id: -11,
  author: "bot",
  data: [
    {
      created_date: "",
      response: [
        {
          type: "html", 
          text: `Hello @@USER_FNAME@@, welcome to i2B's generative AI Q&A tool. Presently I am trained to answer questions 
          in domains of Business Operations, Talent Supply Chain (TSC) and Human Resources (HR) specifically from the dashboard 
          'Cross-Functional Analytics and Drill Down'. Other functional areas, dashboards and metrics will be included within my 
          scope in future releases.<br/><br/>
          Would you like me to answer a question for you on the above-mentioned topics?<br/><br/>
          For more guidance on questions to ask, kindly check out the link below for sample questions.`,
        },
      ],
    },
  ],
  created_date: "",
};

// WEBSOCKET ERROR MESSAGES
export const WS_DB_CON_ERR = "database connection error";
export const WS_REDIS_CON_ERR = "redis connection error";
export const WS_ALGO_ERR = "algo service error";
export const WS_REDIS_ERR = "redis error";
export const WS_DB_ERR = "database error";

// SHOW MORE/LESS
export const defaultHeight = 200;

// SORTING BASED ON MONTH
export const referenceMonthNames = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
export const acnReferenceMonthNames = [
  "sep",
  "oct",
  "nov",
  "dec",
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
];

// CURRENCY CONVERSION
export const acnCurrencyCol = [
  "revenue",
  "new booking",
  "qualified pipeline",
  "ci",
  "del cci",
  "won cci",
];
