import axios from "axios";
import { toast } from "react-toastify";

import MESSAGE from "./apiMessage";
import { API_ROOT } from "../utils/appConstants";
import AuthenticationService from "../utils/authenticationService";

//to set cookie from server, withCredentials should be set true
axios.defaults.withCredentials = true;

// Authentication API's
export const loginSSO = () => {
  return axios
    .get(API_ROOT + `login-redirect`)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(MESSAGE.LOGIN_FAILURE, { theme: "dark" });
    });
};

export const loginBasicAuth = (userCredential) => {
  var formData = new FormData();
  formData.append("username", userCredential?.username);
  formData.append("password", userCredential?.password);
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(API_ROOT + `login`, formData, headers);
};

export const refreshTokens = () => {
  return axios
    .post(API_ROOT + `refresh-token`)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(
        err?.response?.data?.message || MESSAGE.REFRESH_TOKEN_FAILURE,
        {
          theme: "dark",
        }
      );
      AuthenticationService.clearLocalStorageAttributes();
      window.location.replace("/");
    });
};

export const logout = () => {
  return axios
    .post(API_ROOT + `logout`)
    .then((res) => {
      if (res?.status === 200) {
        toast.success(MESSAGE.LOGOUT_SUCCESS, { theme: "dark" });
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || MESSAGE.LOGOUT_FAILURE, {
        theme: "dark",
      });
    });
};

export const getAuthCredentials = () => {
  return axios
    .get(API_ROOT + `user-info`)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(MESSAGE.AUTHCREDENTIAL_FAILURE, { theme: "dark" });
    });
};

// Chat history API
export const getChatHistory = (pagination) => {
  return axios
    .get(API_ROOT + `chat/history`, {
      params: {
        skip: pagination?.skip,
        limit: pagination?.limit,
        page_refresh: !pagination?.isScroll,
      },
    })
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(MESSAGE.CHATHISTORY_FAILURE, { theme: "dark" });
    });
};

export const getFaqs = () => {
  return axios
    .get(API_ROOT + `faqs`)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(MESSAGE.FAQS_FAILURE, { theme: "dark" });
    });
};

// Regenerate response API
export const postRegenerate = (reqObj) => {
  return axios
    .post(API_ROOT + `regenerate/response`, reqObj)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(MESSAGE.RESPONSE_REGENERATE_FAILURE, { theme: "dark" });
    });
};

// Update Thumbs up/down API
export const updateFeedback = (reqObj) => {
  return axios
    .put(API_ROOT + `feedback`, reqObj)
    .then((res) => {
      if (res?.status === 200) {
        return res?.data;
      } else throw res;
    })
    .catch((err) => {
      toast.error(MESSAGE.FEEDBACK_UPDATE_FAILURE, { theme: "dark" });
    });
};
